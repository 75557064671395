import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  word-break: break-word;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 72px 0;
  @media only screen and (max-width: 1440px) {
    margin: 72px 0;
  }
  @media only screen and (max-width: 1366px) {
    margin: 72px 0;
  }
  @media only screen and (max-width: 1024px) {
   margin: 64px 0;
  }
  @media only screen and (max-width: 480px) {
    margin: 48px 0;
  }
  @media only screen and (max-width: 360px) {
    margin: 36px 0;
  }
`;

export const TextContent = styled.div`
  h2 {
    color: #0e212f;
    font-weight: 700;
    font-size: 62px;
    line-height: 1.13;
    letter-spacing: -2px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1440px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 1366px) {
      font-size: 45px;
      line-height: 1.3;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 34px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 28px;
    }
  }
  p {
    color: #0e212f;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.9;
    letter-spacing: -0.3px;
    margin-bottom: 45px;
    text-align: justify;
  }
`;

export const Illustration = styled.figure`
  min-width: 550px;
  @media only screen and (max-width: 1200px) {
    min-width: 461px;
  }
  @media only screen and (max-width: 768px) {
    img {
      border-radius: 5px 5px 0 0;
    }
  }
  @media only screen and (max-width: 480px) {
    min-width: auto;
  }
`;

export const TwoSectionsWithLeftHiddenOnMobile = styled.div`
  display: flex;
  justify-content: space-between;
  form {
    padding: 0;
    margin: 0;
  }
  > *  {
      &:first-child {
         padding-right: 64px;
         margin-right: 16px;
         border-right: 1px solid #aaa;
      }
  }
  
  @media only screen and (max-width: 1000px) {
    > *  {
      &:first-child {
         padding-right: 0;
         margin-right: 0;
         border-right: 0;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
`;