import React from 'react';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import {
  Section,
  ContentWrapper,
  TextContent,
} from '../page.style';

export default () => {
  return (
    <Section>
      <Container>
        <ContentWrapper>
          <TextContent>
            <Heading content="Datenschutzerklärung"/>
            <div>
              <h2>1. Datenschutz auf einen Blick</h2>
              <h3>Allgemeine Hinweise</h3>
              <p>
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen
                Daten
                passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz
                entnehmen
                Sie
                unserer unter diesem Text aufgeführten Datenschutzerklärung.
              </p>

              <h3>Datenerfassung auf dieser Website</h3>

              <strong>
                Wer ist verantwortlich für die Datenerfassung auf dieser Website?
              </strong>
              <p>
                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                können
                Sie dem Impressum dieser Website entnehmen.
              </p>

              <strong>
                Wie erfassen wir Ihre Daten?
              </strong>
              <p>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich
                z.
                B. um
                Daten handeln, die Sie in ein Kontaktformular eingeben.
              </p>
              <p>
                Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch
                unsere
                IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem
                oder
                Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
                Website
                betreten.
              </p>

              <strong>
                Wofür nutzen wir Ihre Daten?
              </strong>

              <p>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
                gewährleisten.
                Andere
                Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
              </p>
              <strong>
                Welche Rechte haben Sie bezüglich Ihrer Daten?
              </strong>
              <p>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
                Berichtigung
                oder
                Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt
                haben,
                können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das
                Recht,
                unter
                bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                verlangen.
                Des
                Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
              </p>
              <p>
                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
                Impressum
                angegebenen Adresse an uns wenden.
              </p>
              <strong>
                Analyse-Tools und Tools von Drittanbietern
              </strong>
              <p>
                Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht
                vor
                allem mit Cookies und mit sogenannten Analyseprogrammen.
              </p>
              <p>
                Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
                Datenschutzerklärung.
              </p>

              <h2>
                2. Hosting und Content Delivery Networks (CDN)
              </h2>
              <h3>
                Externes Hosting
              </h3>
              <p>
                Diese Website wird bei einem externen Dienstleister gehostet (Google Cloud). Die
                personenbezogenen
                Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert.
                Hierbei
                kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
                Vertragsdaten,
                Kontaktdaten, Namen, Webseitenzugriffe und sonstige Daten, die über eine Website generiert
                werden,
                handeln.
              </p>
              <p>
                Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen
                und
                bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
                effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6
                Abs.
                1
                lit. f DSGVO).
              </p>
              <p>
                Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
                Leistungspflichten
                erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
              </p>
              <h3>
                Abschluss eines Vertrages über Auftragsverarbeitung
              </h3>
              <p>
                Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über
                Auftragsverarbeitung mit unserem Hoster geschlossen.
              </p>
              <h2>
                3. Allgemeine Hinweise und Pflichtinformationen
              </h2>
              <h3>Datenschutz</h3>
              <p>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
                Ihre
                personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften
                sowie
                dieser Datenschutzerklärung.
              </p>
              <p>
                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                Personenbezogene
                Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert
                auch,
                wie und zu welchem Zweck das geschieht.
              </p>
              <p>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per
                E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte
                ist
                nicht möglich.
              </p>
              <h3>Hinweis zur verantwortlichen Stelle</h3>
              <p>
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist: <strong>Easypickup Tech GmbH</strong><br/>
              </p>
              <address>
                Papenstraße 5A, <br/>
                22089 Hamburg<br/>
                Deutschland<br/>
              </address>
              <strong>Telefon:</strong> +4917671201193<br/>
              <strong>E-Mail:</strong> privacy@easyresto.de
              <p></p>
              <p>
                Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit
                anderen
                über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen,
                E-Mail-Adressen o.
                Ä.) entscheidet.
              </p>
              <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>

              <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie
                können
                eine
                bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
                E-Mail
                an
                uns.
              </p>

              <h3>Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
                unberührt.</h3>

              <strong>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung
                (Art.
                21 DSGVO)
              </strong>

              <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE
                JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
                VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF
                DIESE
                BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG
                BERUHT,
                ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE
                BETROFFENEN
                PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
                GRÜNDE
                FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
                VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
                (WIDERSPRUCH
                NACH
                ART. 21 ABS. 1 DSGVO).
              </p>

              <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS
                RECHT,
                JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
                DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
                DIREKTWERBUNG
                IN
                VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                NICHT
                MEHR
                ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
              </p>
              <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>

              <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                Arbeitsplatzes
                oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet
                anderweitiger
                verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
              </p>

              <h3>Recht auf Datenübertragbarkeit</h3>

              <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
                Vertrags
                automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren
                Format
                aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
                Verantwortlichen
                verlangen, erfolgt dies nur, soweit es technisch machbar ist.
              </p>

              <h3>SSL- bzw. TLS-Verschlüsselung</h3>

              <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte,
                wie
                zum
                Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
                TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
                Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
              </p>
              <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
                übermitteln,
                nicht von Dritten mitgelesen werden.
              </p>
              <h3>Auskunft, Löschung und Berichtigung</h3>

              <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
                unentgeltliche

                Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den

                Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten.
                Hierzu
                sowie
              </p>

              <p>zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im
                Impressum
                angegebenen Adresse an uns wenden.</p>

              <h3>Recht auf Einschränkung der Verarbeitung</h3>

              <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                verlangen.
                Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das
                Recht
                auf
                Einschränkung der Verarbeitung besteht in folgenden Fällen:
              </p>
              <p>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
                benötigen
                wir
                in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </p>
              <p>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
                statt
                der
                Löschung die Einschränkung der Datenverarbeitung verlangen.
              </p>
              <p>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                Verteidigung
                oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </p>
              <p>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                Ihren
                und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                überwiegen,
                haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                verlangen.
              </p>
              <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten –
                von
                ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                juristischen
                Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
                eines
                Mitgliedstaats verarbeitet werden.
              </p>
              <strong>Widerspruch gegen Werbe-E-Mails</strong>

              <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
                nicht
                ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
                Betreiber
                der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
                von
                Werbeinformationen, etwa durch Spam-E-Mails, vor.
              </p>
              <h2>4. Datenerfassung auf dieser Website</h2>

              <h3>Cookies</h3>

              <p>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und
                richten
                auf
                Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung
                (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert.
                Session-Cookies
                werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
                Endgerät
                gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser
                erfolgt.</p>

              <p>Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn
                Sie
                unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
                bestimmter
                Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung von
                Zahlungsdienstleistungen).
              </p>
              <p>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
                Webseitenfunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
                Anzeige
                von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
                anzuzeigen.
              </p>
              <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder
                zur
              </p>

              <p>Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die
                Warenkorbfunktion) oder zur Optimierung der Webseite (z.B. Cookies zur Messung des Webpublikums)
                erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine
                andere
                Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der
                Speicherung
                von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern
                eine
                Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der
                betreffenden
                Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die
                Einwilligung
                ist jederzeit widerrufbar.
              </p>
              <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden
                und
                Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
                ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren.
                Bei
                der
                Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
              </p>
              <p>Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie
                hierüber
                im
                Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.
              </p>
              <strong>Anfrage per E-Mail, Telefon oder Telefax</strong>

              <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller
                daraus
                hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres
                Anliegens
                bei
                uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
              </p>
              <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre
                Anfrage
                mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten
                Interesse
                an
                der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
                Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
              </p>
              <p>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur
                Löschung
                auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung
                entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche
                Bestimmungen –
                insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
              </p>
              <strong>Kommentarfunktion auf dieser Website</strong>

              <p>Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar auch Angaben zum
                Zeitpunkt
                der
                Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen
                gewählte
                Nutzername gespeichert.
              </p>
              <strong>Speicherdauer der Kommentare</strong>

              <p>Die Kommentare und die damit verbundenen Daten (z. B. IP-Adresse) werden gespeichert und
                verbleiben
                auf
                dieser Website, bis der kommentierte Inhalt vollständig gelöscht wurde oder die Kommentare aus
                rechtlichen Gründen gelöscht werden müssen (z. B. beleidigende Kommentare).
              </p>
              <strong>Rechtsgrundlage</strong>

              <p>Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
                DSGVO).
                Sie können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
                Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten
                Datenverarbeitungsvorgänge
                bleibt
                vom Widerruf unberührt.
              </p>
              <h2>5. Soziale Medien</h2>

              <h3>Facebook Plugins (Like &amp; Share-Button)</h3>

              <p>Auf dieser Website sind Plugins des sozialen Netzwerks Facebook integriert. Anbieter dieses
                Dienstes
                ist
                die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden
                nach
                Aussage von Facebook jedoch auch in die USA und in andere Drittländer übertragen.
              </p>
              <p>Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem „Like-Button“ („Gefällt mir“) auf
                dieser
                Website. Eine Übersicht über die Facebook Plugins finden Sie hier:
                https://developers.facebook.com/docs/plugins/?locale=de_DE.
              </p>
              <p>Wenn Sie diese Website besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem
                Browser
                und
                dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer
                IP-Adresse
                diese Website besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken während Sie in Ihrem
                Facebook-Account eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem
                Facebook-Profil
                verlinken. Dadurch kann Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir
                weisen
                darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten
                sowie
                deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der
                Datenschutzerklärung von Facebook unter:
                https://de-de.facebook.com/privacy/explanation.
              </p>

              <p>Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website Ihrem Facebook-Nutzerkonto
                zuordnen
                kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
              </p>
              <p>Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                Websitebetreiber hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit in
                den
                Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
                Verarbeitung
                ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
                widerrufbar.
              </p>
              <h3>Twitter Plugin</h3>

              <p>Auf dieser Website sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden
                angeboten
                durch die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Durch das
                Benutzen
                von Twitter und der Funktion „Re-Tweet“ werden die von Ihnen besuchten Websites mit Ihrem
                Twitter-Account verknüpft und anderen Nutzern bekannt gegeben. Dabei werden auch Daten an
                Twitter
                übertragen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
                der
                übermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere Informationen hierzu
                finden
                Sie
                in der Datenschutzerklärung von Twitter unter: https://twitter.com/de/privacy.
              </p>
              <p>Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                Websitebetreiber hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit in
                den
                Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
                Verarbeitung
                ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
                widerrufbar.
              </p>
              <p>Ihre Datenschutzeinstellungen bei Twitter können Sie in den Konto-Einstellungen unter
                https://twitter.com/account/settings ändern.
              </p>
              <h3>Instagram Plugin</h3>

              <p>Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden
                angeboten
                durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025, USA integriert.
              </p>
              <p>Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch Anklicken des
                Instagram-Buttons
                die Inhalte dieser Website mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den
                Besuch
                dieser
              </p>

              <p>Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten
                keine
                Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
              </p>
              <p>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                Webseitenbetreiber hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit
                in
                den
                Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
                Verarbeitung
                ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
                widerrufbar.
              </p>
              <p>Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram:

                https://instagram.com/about/legal/privacy/.
              </p>

              <h2>6. Newsletter</h2>

              <h3>Newsletterdaten</h3>

              <p>Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen
                eine
                E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber
                der
                angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere
                Daten
                werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich
                für
                den
                Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.
              </p>
              <p>Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich
                auf
                Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
                Speicherung
                der
                Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit
                widerrufen, etwa über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
                erfolgten
                Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
              </p>
              <p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu
                Ihrer
                Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach
                der
                Abbestellung des Newsletters aus der Newsletterverteilerliste gelöscht. Daten, die zu anderen
                Zwecken
                bei uns gespeichert wurden bleiben hiervon unberührt.
              </p>
              <p>Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem
                Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um künftige Mailings zu
                verhindern.
                Die
                Daten aus der Blacklist werden nur für diesen Zweck verwendet und nicht mit anderen Daten
                zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung
                der
                gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6
                Abs.
                1
                lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet. Sie können der
                Speicherung
                widersprechen, sofern Ihre Interessen unser berechtigtes Interesse überwiegen.
              </p>

              <h2>7. Plugins und Tools</h2>

              <h3>YouTube mit erweitertem Datenschutz</h3>

              <p>Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist die Google Ireland Limited
                („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
              </p>
              <p>Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt laut YouTube, dass
                YouTube
                keine Informationen über die Besucher auf dieser Website speichert, bevor diese sich das Video
                ansehen.
                Die Weitergabe von Daten an YouTube-Partner wird durch den erweiterten Datenschutzmodus hingegen
                nicht
                zwingend ausgeschlossen. So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen –
                eine
                Verbindung zum Google DoubleClick-Netzwerk her.
              </p>

              <p>Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine Verbindung zu den Servern von
              </p>


              <p>YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
                haben.

                Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten
                direkt

                Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
                YouTube-

                Account ausloggen.</p>

              <p>Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf Ihrem Endgerät
                speichern.
                Mit Hilfe dieser Cookies kann YouTube Informationen über Besucher dieser Website erhalten. Diese
                Informationen werden u. a. verwendet, um Videostatistiken zu erfassen, die
                Anwenderfreundlichkeit zu
                verbessern und Betrugsversuchen vorzubeugen. Die Cookies verbleiben auf Ihrem Endgerät, bis Sie
                sie
                löschen.
              </p>

              <p>Gegebenenfalls können nach dem Start eines YouTube-Videos weitere Datenverarbeitungsvorgänge
                ausgelöst
                werden, auf die wir keinen Einfluss haben.
              </p>
              <p>Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer
                Online-Angebote.
                Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
                entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
                Grundlage
                von
                Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </p>

              <p>Weitere Informationen über Datenschutz bei YouTube finden Sie in deren Datenschutzerklärung
                unter:

                https://policies.google.com/privacy?hl=de.
              </p>

              <h3>Google Maps</h3>

              <p>Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Ireland
                Limited
                („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
              </p>
              <p>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese
                Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort
                gespeichert.
                Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
              </p>
              <p>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer
                Online-Angebote
                und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt
                ein
                berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende
                Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
                Abs.
                1
                lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </p>
              <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:

                https://policies.google.com/privacy?hl=de.
              </p>

              <h3>OpenStreetMap</h3>

              <p>Wir nutzen den Kartendienst von OpenStreetMap (OSM). Anbieterin ist die Open-Street-Map
                Foundation
                (OSMF), 132 Maney Hill Road, Sutton Coldfield, West Midlands, B72 1JU, United Kingdom.
              </p>
              <p>Wenn Sie eine Website besuchen, auf der OpenStreetMap eingebunden ist, werden u. a. Ihre
                IP-Adresse
                und
                weitere Informationen über Ihr Verhalten auf dieser Website an die OSMF weitergeleitet.
                OpenStreetMap
                speichert hierzu unter Umständen Cookies in Ihrem Browser. Das sind Textdateien, die auf Ihrem
                Computer
                gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Sie
                können
                die
                Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
                wir
                weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
                dieser
                Website vollumfänglich werden nutzen können.
              </p>
              <p>Ferner kann Ihr Standort erfasst werden, wenn Sie dies in Ihren Geräteeinstellungen – z. B. auf
                Ihrem
                Handy – zugelassen haben. Der Anbieter dieser Seite hat keinen Einfluss auf diese
                Datenübertragung.
                Details</p>

              <p>entnehmen Sie der Datenschutzerklärung von OpenStreetMap unter folgendem Link:

                https://wiki.osmfoundation.org/wiki/Privacy_Policy.
              </p>
              <p>Die Nutzung von OpenStreetMap erfolgt im Interesse einer ansprechenden Darstellung unserer
                Online-Angebote und einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte.
                Dies
                stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
                entsprechende
                Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
                jederzeit
                widerrufbar.
              </p>
            </div>
          </TextContent>
        </ContentWrapper>
      </Container>
    </Section>
  );
};
