import React from "react";

import Page from 'containers/page';
import AGB from 'containers/AGB';


export default () => {
  return <Page seoProps={{
    title: "Datenschutzerklärung | Easyresto",
  }}>
    <AGB/>
  </Page>
}
